import React, { useEffect, useState, FormEvent, useRef } from 'react';
import { inputId } from '../../shared/lib/utils';
import { IInputElement } from '../../shared/lib/types';
import FormGroupLabelText from './FormGroupLabelText';
import FormError from './FormError';

export interface INumberInput extends IInputElement {
    required?: boolean;
    answerHidden?: boolean;
    error?: string;
    showErrorMessage?: boolean;
    step?: string;
}

const NumberInput = (props: INumberInput) => {
    const [id] = useState(inputId());
    const input = useRef<HTMLInputElement>(null);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        props.onInputChange(props.name, e.currentTarget.value);
    };

    function getInputClass() {
        let className = 'form-group__input';

        if (props.disabled) {
            className += ' disabled';
        }

        if (props.error) {
            className += ' error-input';
        }

        return className;
    }

    useEffect(() => {
        if (props.focus === props.name) {
            input?.current?.focus();
        }
    }, [props.focus, props.name]);

    return (
        <div className="form-group form-group--number">
            <label htmlFor={id}>
                <FormGroupLabelText label={`${props.label}${props.required ? '*' : ''}`} helpText={props.helpText} />
                <input
                    type={props.type}
                    id={id}
                    ref={input}
                    className={getInputClass()}
                    name={props.name}
                    aria-required={props.required}
                    value={props.value}
                    onChange={handleChange}
                    disabled={props.disabled}
                    hidden={props.answerHidden}
                />
                {(props.error && props.showErrorMessage) && <FormError error={props.error} />}
            </label>
        </div>
    );
};

export default NumberInput;
