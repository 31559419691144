import { CollapseWithChild } from '../../../../prototype/components/Collapse';
import { IPathSteps, IVeilederStep } from '../../shared/lib/types';

interface IProgressbar {
    isActive: boolean;
    pathSteps: Array<IPathSteps>;
    steps: Array<IVeilederStep>;
    stepNumber: number;
    onSelectedStep(index: number): void;
}

export default function Progressbar({ steps, stepNumber, pathSteps, isActive, onSelectedStep }: IProgressbar) {

    return (
        <div className="veileder-progress d-flex align-items-center">
            <CollapseWithChild name={`Steg (${pathSteps.length})`}>
                {pathSteps?.map((step, index) => (
                    <div
                        className={`veileder-progress__steg${step.stepNumber === stepNumber ? ' active' : ''}`}
                        onClick={() => {
                            if (isActive) {
                                onSelectedStep(step.stepNumber);
                            }
                        }}
                    >
                        <div className="steg">
                            <div className="steg--header">
                                { index + 1}
                                {'. '}
                                {step.stepHeader}
                            </div>

                            { isStepProcessed(steps,step.stepNumber) && <span className="icon icon-check" />}
                        </div>
                        <div className="divider"></div>
                    </div>
                ))}
            </CollapseWithChild>
        </div>
    );
}

const isStepProcessed = (steps: Array<IVeilederStep>, stepNumber: number):boolean => {
    return steps.filter((s) => s.stepNumber === stepNumber)[0].stepProcessed;
};