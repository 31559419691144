import Progressbar from '../progressbar/Progressbar';
import PagingActive from '../paging/PagingActive';
import PagingDisabled from '../paging/PagingDisabled';
import { IPathSteps, IVeilederStep } from '../../shared/lib/types';

interface IVeilederFooter {
    visFullBred?: boolean;
    stepNumber: number;
    isActive: boolean;
    pathSteps: Array<IPathSteps>;
    steps: Array<IVeilederStep>;
    onSelectedStep(index: number): void;
    onNextStepClick(): void;
    onPrevStepClick(): void;
    onGjenopptaStepClick(): void;
    onAvsluttStepClick(): void;
}

export default function VeilederFooter({
    visFullBred,
    stepNumber,
    isActive,
    pathSteps,
    steps,
    onSelectedStep,
    onNextStepClick,
    onPrevStepClick,
    onGjenopptaStepClick,
    onAvsluttStepClick,
}: IVeilederFooter) {
    const stepIndex = steps.findIndex((step) => step.stepNumber === stepNumber);
    
    return (
        <div className="veileder-footer">
            <div className={visFullBred ? 'container-full' : 'container'}>
                <div className="veileder-footer__inner">
                    <Progressbar steps={steps} stepNumber={stepNumber} isActive={isActive} pathSteps={pathSteps} onSelectedStep={onSelectedStep} />
                    {isActive ? (
                        <PagingActive stepIndex={stepIndex} onNextStepClick={onNextStepClick} onPrevStepClick={onPrevStepClick} />
                    ) : (
                        <PagingDisabled onGjenopptaStepClick={onGjenopptaStepClick} onAvsluttStepClick={onAvsluttStepClick} />
                    )}
                </div>
            </div>
        </div>
    );
}
