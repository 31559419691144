import React, { useState } from 'react';
import { IVeilederQuestion } from '../../../shared/lib/types';
import VeilederTabularRow from './VeilederTabularRow';

export interface IVeilederTabularProps extends IVeilederQuestion {
    onValueChange(questionNumber: number, rowIndex: number, cellIndex: number, value: string | number | boolean): void;
    onAddRow(questionNumber: number): void;
    onDeleteRow(questionNumber: number, rowIndex: number): void;
    disabled?: boolean;
    debugLabel?: string;
}

const VeilederTabular = (props: IVeilederTabularProps) => {
    const [show, setShow] = useState(false);
    const classNames = ['form-group', 'form-group--tabular'];
    if (props.tabularValue?.canAddRows) {
        classNames.push('form-group--can-add-rows');
    }

    function handleValueChange(rowIndex: number, cellIndex: number, value: string | number | boolean) {
        props.onValueChange(props.questionNumber, rowIndex, cellIndex, value);
    }

    function handleDeleteRow(rowIndex: number) {
        props.onDeleteRow(props.questionNumber, rowIndex);
    }

    function handleAddRowClick() {
        props.onAddRow(props.questionNumber);
    }

    function renderRows() {       
        return (
            (props.tabularValue?.rows?.length &&
                props.tabularValue?.rows.map((row, i) => {
                    return (
                        <VeilederTabularRow
                            columns={row.cells}
                            rowIndex={i}
                            canAddRows={props.tabularValue?.canAddRows}
                            headerColumns={props.tabularValue?.columns ?? []}
                            onValueChange={handleValueChange}
                            onDeleteRow={handleDeleteRow}
                            key={`${props.questionNumber}_row_${i}`}
                            disabled={props.disabled}
                            showDebugLabel={props.debugLabel && true}
                        />
                    );
                })) ||
            null
        );
    }
    
    const qText = props.debugLabel ? `${props.questionText} ${props.debugLabel}` : props.questionText;
    
    return (
        <div className={classNames.join(' ')}>
            <p className="form-group__label">
                <span className="form-group__tabular-label" dangerouslySetInnerHTML={{ __html: qText }}></span>
                {props.extraQuestionDescription && props.extraQuestionDescription.length && (
                    <span className="icon icon-hjelp_stor icon--help" onClick={() => setShow(!show)} />
                )}
            </p>
            {show && (
                <div className="alert alert-success">
                    <span className="icon icon-hjelp_stor"></span>
                    <div dangerouslySetInnerHTML={{ __html: props.extraQuestionDescription }} />
                </div>
            )}
            {props.tabularValue?.columns && <ul className="list--unstyled mt-0 mb-0">{renderRows()}</ul>}
            {props.tabularValue?.canAddRows && (
                <button className="btn btn--primary" onClick={handleAddRowClick} disabled={props.disabled}>
                    Legg til rad
                </button>
            )}
        </div>
    );
};

export default VeilederTabular;
