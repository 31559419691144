import React from 'react';
import 'flatpickr/dist/themes/light.css';
import Flatpickr from 'react-flatpickr';
import { Norwegian } from 'flatpickr/dist/l10n/no.js';
import { IInputElement } from '../../shared/lib/types';
import FormGroupLabelText from './FormGroupLabelText';
import FormError from './FormError';

export interface IDateInput extends IInputElement {
    required?: boolean;
    options?: object;
    answerHidden?: boolean;
    showErrorMessage?: boolean;
    error?: string;
}

const DateInput = (props: IDateInput) => {
    let options = {
        altFormat: 'Z',
        dateFormat: 'd.m.Y',
        locale: Norwegian,
    };

    if (props.options) {
        Object.assign(options, props.options);
    }

    function handleChange(date: Date): void {
        props.onInputChange(props.name, date?.toISOString() || '');
    }
    function getInputClass() {
        let className = 'form-group__input';

        if (props.disabled) {
            className += ' disabled';
        }

        if (props.error) {
            className += ' error-input';
        }

        return className;
    }
    const date = props.value && !props.value.startsWith('0001') ? new Date(props.value) : '';

    return (
        !props.answerHidden && (
            <div className="form-group form-group--date">
                <label>
                    <FormGroupLabelText label={`${props.label}${props.required ? '*' : ''}`} helpText={props.helpText} />
                    <div className="date form-group__input">
                        <Flatpickr
                            required={props.required}
                            className={getInputClass()}
                            value={date}
                            onChange={([date]) => handleChange(date)}
                            options={options}
                            disabled={props.disabled}
                        />
                        <div className={`date__addon ${props.disabled && 'disabled'}`}></div>
                    </div>
                    {props.error && props.showErrorMessage && <FormError error={props.error} />}
                </label>
            </div>
        )
    );
};

export default DateInput;
