import React from 'react';
import { IInputElement } from '../../shared/lib/types';
import FormError from './FormError';
import FormGroupLabelText from './FormGroupLabelText';
import RadioInput, { IRadioInput } from './RadioInput';

export interface IRadioGroup extends IInputElement {
    legend: string;
    inputs: Array<IRadioInput>;
    debugLabel?: string;
    answerHidden?: boolean;
    required?: boolean;
    showErrorMessage?: boolean;
    error?:string;
}

const RadioGroup = (props: IRadioGroup) => {
    const label = `${props.legend}${props.required ? '*' : ''}`;

    return (
        <div className="form-group form-group--radio-group">
            <fieldset name={props.legend}>
                <legend className="form-group__label-text">
                    <FormGroupLabelText
                        label={props.debugLabel ? `${label} ${props.debugLabel}`: label}
                        helpText={props.helpText}
                    />
                </legend>
                <ul className="list--unstyled">
                    {props.inputs.length &&
                        props.inputs.map((input, index) => {
                            return (
                                <li key={input.name + '_' + input.value}>
                                    <RadioInput
                                        {...input}
                                        index={index}
                                        focus={props.focus}
                                        onInputChange={props.onInputChange}
                                    />
                                </li>
                            );
                        })}
                </ul>
            </fieldset>
            {(props.error && props.showErrorMessage) && <FormError error={props.error} />}
        </div>
    );
};

export default RadioGroup;
