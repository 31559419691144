interface IPaging {
    stepIndex: number;
    onNextStepClick(): void;
    onPrevStepClick(): void;
}
export default function PagingActive({stepIndex,onPrevStepClick,onNextStepClick}:IPaging){
    return(
        <div className="row justify-content-center footer__paging">
        <div className="col-md-6 d-flex justify-content-end veileder-footer__inner__paging">
            {stepIndex === 0 ? null : (
                <button className="btn" disabled={stepIndex === 0} onClick={onPrevStepClick}>
                    <span className="icon icon-arrow-left" />
                    Forrige steg
                </button>
            )}
        </div>
        <div className="col-md-6 veileder-footer__inner__paging">
            <button className="btn" onClick={onNextStepClick}>
                Neste steg
                <span className="icon icon-arrow-right" />
            </button>
        </div>
    </div>
    )
}