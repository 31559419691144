interface IPagingDisabled {
    onGjenopptaStepClick(): void;
    onAvsluttStepClick(): void;
}
export default function PagingDisabled({ onGjenopptaStepClick, onAvsluttStepClick }: IPagingDisabled) {
    return (
        <div className="row justify-content-center footer__paging">
            <div className="col-md-6 d-flex justify-content-end veileder-footer__inner__paging">
                <button className="btn" onClick={onGjenopptaStepClick}>
                    Gjenoppta herfra
                </button>
            </div>
            <div className="col-md-6 veileder-footer__inner__paging">
                <button className="btn" onClick={onAvsluttStepClick}>
                    Avslutt
                </button>
            </div>
        </div>
    );
}
