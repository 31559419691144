import React from 'react';
import { IVeilederTabularHeaderCell, IVeilederTabularRowCell, VeilederAnswerNames } from '../../../shared/lib/types';
import VeilederQuestion, { IVeilederQuestionProps } from '../VeilederQuestion';

export interface IVeilederTabularRowProps {
    column: IVeilederTabularRowCell;
    cellIndex: number;
    headerColumn: IVeilederTabularHeaderCell;
    onValueChange(cellIndex: number, value: any): void;
    disabled?: boolean;
    showDebugLabel?: boolean;
}

const VeilederTabularCell = (props: IVeilederTabularRowProps) => {
    function handleValueChange(questionNumber: number, value: any) {
        props.onValueChange(props.cellIndex, value);
    }

    const debugLabel = `[${VeilederAnswerNames[props.headerColumn.type]}]`;
    const questionProps = {
        questionNumber: props.headerColumn.number,
        questionText: props.headerColumn.header,
        questionHidden: true,
        answerType: props.headerColumn.type,
        alternativeValues: props.headerColumn.alternativesValue || [],
        stringValue: props.column.stringValue,
        numberValue: props.column.numberValue,
        doubleValue: props.column.doubleValue,
        dateValue: props.column.dateValue?.toString(),
        onInputChange: handleValueChange,
        disabled: props.disabled || props.column.readOnly,
        debugLabel: props.showDebugLabel && debugLabel,
        readonly: props.column.readOnly,
        questionOptional: props.headerColumn.isOptional,
        validationErrorMessage: props.column.validationErrorMessage,
        showErrorMessage: true
    } as IVeilederQuestionProps;

    return <VeilederQuestion {...questionProps} />;
};

export default VeilederTabularCell;
