import React from 'react';
import { IVeilederQuestion, IVeilederStep, VeilederAnswerEnum, VeilederAnswerNames } from '../shared/lib/types';
import VeilederQuestion from './questions/VeilederQuestion';
import VeilederTabular from './questions/tabular/VeilederTabular';
import helper from './veilederHelper';

export interface IVeilederStepProps {
    fullBred: boolean;
    step: IVeilederStep;
    updateVeilederSteg(steg: IVeilederStep): void;
    ksDebugger?: boolean;
}

const VeilederSteg = (props: IVeilederStepProps) => {
    function handleAnswerChange(questionNumber: number, value: any) {
        updateQuestions(helper.valueChange(props.step.questions, questionNumber, value));
    }

    function handleTabularAnswerChange(questionNumber: number, rowIndex: number, cellIndex: number, value: any) {
        updateQuestions(helper.tabularValueChange(props.step.questions, questionNumber, rowIndex, cellIndex, value));
    }

    function handleTabularAddRow(questionNumber: number) {
        updateQuestions(helper.tabularAddRow(props.step.questions, questionNumber));
    }

    function handleTabularDeleteRow(questionNumber: number, rowIndex: number) {
        updateQuestions(helper.tabularDeleteRow(props.step.questions, questionNumber, rowIndex));
        if (helper.tabularRowCount(props.step.questions, questionNumber) === 0) {
            helper.tabularAddRow(props.step.questions, questionNumber);
        }
    }

    function updateQuestions(updatedQuestions: Array<IVeilederQuestion>) {
        props.updateVeilederSteg({
            ...props.step,
            questions: updatedQuestions,
        } as IVeilederStep);
    }

    function renderQuestion(q: IVeilederQuestion, isActive: boolean) {
        const debugLabel = props.ksDebugger ? `[${q.questionNumber}] [${VeilederAnswerNames[q.answerType]}] [${q.questionTag}]` : null;
         
        if (q.answerType === VeilederAnswerEnum.tabular) {
            return (
                <VeilederTabular
                    {...q}
                    disabled={!isActive}
                    onValueChange={handleTabularAnswerChange}
                    onAddRow={handleTabularAddRow}
                    onDeleteRow={handleTabularDeleteRow}
                    key={`veileder_question_${q.questionNumber}`}
                    debugLabel={props.ksDebugger && debugLabel}
                />
            );
        }

        return (
            <VeilederQuestion
                {...q}
                readonly={!isActive}
                onInputChange={handleAnswerChange}
                key={`veileder_question_${q.questionNumber}`}
                debugLabel={props.ksDebugger && debugLabel}
                showErrorMessage={true}
            />
        );
    }

    return (
        <div className={props.fullBred ? 'container-full' : 'container'}>
            {props.step.description && !!props.step.description.length && (
                <p className="veileder-steg-lead" dangerouslySetInnerHTML={{ __html: props.step.description }}></p>
            )}
            {props.step.questions.map((question: IVeilederQuestion) => {
                if (!question.questionHidden) {
                    return renderQuestion(question, props.step.isActive);
                }
                
                return null;
            })}
        </div>
    );
};

export default VeilederSteg;
