import React from 'react';
import { IVeilederTabularHeaderCell, IVeilederTabularRowCell } from '../../../shared/lib/types';
import VeilederTabularCell from './VeilederTabularCell';

export interface IVeilederTabularRowProps {
    rowIndex: number;
    canAddRows?: boolean;
    columns: Array<IVeilederTabularRowCell>;
    headerColumns: Array<IVeilederTabularHeaderCell>;
    onValueChange(rowIndex: number, cellIndex: number, value: any): void;
    onDeleteRow(rowIndex: number): void;
    disabled?: boolean;
    showDebugLabel?: boolean;
}

const VeilederTabularRow = (props: IVeilederTabularRowProps) => {
    function handleValueChange(cellIndex: number, value: any) {
        props.onValueChange(props.rowIndex, cellIndex, value);
    }

    function handleDeleteRowClick() {
        props.onDeleteRow(props.rowIndex);
    }

    function renderColumns() {
        return props.columns?.map((col, i) => {
            return (
                <VeilederTabularCell
                    column={col}
                    cellIndex={i}
                    headerColumn={props.headerColumns[i]}
                    onValueChange={handleValueChange}
                    key={`row_${props.rowIndex}_cell_${i}`}
                    disabled={props.disabled}
                    showDebugLabel={props.showDebugLabel}
                />
            );
        });
    }

    return (
        <li className="form-group__tabular-row">
            {renderColumns()}
            {props.canAddRows && (
                <div>
                    <button className="btn btn--primary" onClick={handleDeleteRowClick} disabled={props.disabled}>Slett</button>
                </div>
            )}
        </li>
    );
};

export default VeilederTabularRow;
