import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { IInputElement } from '../../shared/lib/types';
import { inputId } from '../../shared/lib/utils';

export interface IRadioInput extends IInputElement {
    checked: boolean;
    index: number;
    answerHidden?: boolean;
}

const RadioInput = (props: IRadioInput) => {
    const [id] = useState(inputId());
    const input = useRef<HTMLInputElement>(null);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        props.onInputChange(props.name, e.currentTarget.value);
    };

    useEffect(() => {
        if (props.name === props.focus && props.index === 0) {
            input?.current?.focus();
        }
    }, [props.focus, props.name, props.index]);

    return (
        <div className="form-group form-group--radio">
            <input
                type="radio"
                id={id}
                ref={input}
                className="form-group__input"
                value={props.value}
                name={props.name}
                checked={props.checked}
                onChange={handleChange}
                disabled={props.disabled}
                hidden={props.answerHidden}
            />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: props.label }}></label>
        </div>
    );
};

export default RadioInput;
