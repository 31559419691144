import React from 'react';
import { InputElementEnum } from '../../../../shared/lib/types';
import { IVeilederQuestion, VeilederAnswerEnum } from '../../shared/lib/types';
import CheckboxGroup, { ICheckboxGroup } from '../../../form/CheckboxGroup';
import { ICheckboxInput } from '../../../form/CheckboxInput';
import RadioGroup, { IRadioGroup } from '../../../form/RadioGroup';
import { IRadioInput } from '../../../form/RadioInput';
import SelectInput, { ISelectInput, ISelectInputOption } from '../../../form/SelectInput';
import TextInput, { ITextInput } from '../../../form/TextInput';
import TextareaInput, { ITextareaInput } from '../../../form/TextareaInput';
import NumberInput, { INumberInput } from '../../../form/NumberInput';
import DateInput, { IDateInput } from '../../../form/DateInput';
import { isTextInput, isDecimalAnswer, isNumberAnswer } from '../veilederHelper';

export interface IVeilederQuestionProps extends IVeilederQuestion {
    onInputChange(questionNumber: number, value: any): void;
    disabled?:boolean;
    debugLabel?: string;
    readonly?: boolean;
    answerHidden?: boolean;
    questionOptional?: boolean;
    showErrorMessage: boolean;
}

const VeilederQuestion = (props: IVeilederQuestionProps) => {
    function handleInputChange(name: string, value: any) {
        props.onInputChange(parseInt(name), value);
    }
    function renderInput() {
        const inputProps = {
            label: props.debugLabel ? `${props.questionText} ${props.debugLabel}` : props.questionText,
            helpText: props.extraQuestionDescription,
            name: props.questionNumber?.toString(),
            required: (!props.questionOptional && !props.answerHidden) ? true : false,
            focus: '',
            answerHidden: props.answerHidden,
            onInputChange: handleInputChange,
            error: props.validationErrorMessage,
            showErrorMessage: props.showErrorMessage
        };

        if (isTextInput(props.answerType) || props.answerType === VeilederAnswerEnum.undefined) {
            const stringProps = {
                ...inputProps,
                type: InputElementEnum.text,
                value: props.stringValue,
                disabled: props.disabled || props.readonly,
                readonly: props.readonly
            } as ITextInput;
            
            return <TextInput {...stringProps} />;
        }
        if (props.answerType === VeilederAnswerEnum.date) {
            const dateProps = {
                ...inputProps,
                type: InputElementEnum.number,
                value: props.dateValue,
                disabled: props.disabled || props.readonly,
            } as IDateInput;
            return <DateInput {...dateProps} />;
        }
        if (isNumberAnswer(props.answerType)) {
            const numberProps = {
                ...inputProps,
                type: InputElementEnum.number,
                value: props.numberValue,
                disabled: props.disabled || props.readonly,
                readonly: props.readonly
            } as INumberInput;
            return <NumberInput {...numberProps} />;
        }
        if (isDecimalAnswer(props.answerType)) {
            const numberProps = {
                ...inputProps,
                type: InputElementEnum.number,
                value: props.doubleValue,
                disabled: props.disabled || props.readonly,
                readonly: props.readonly
            } as INumberInput;
            return <NumberInput {...numberProps} />;
        }
        if (props.answerType === VeilederAnswerEnum.comment) {
            const stringProps = {
                ...inputProps,
                type: InputElementEnum.textarea,
                value: props.stringValue,
                rows: 1,
                disabled: props.disabled || props.readonly,
                readonly: props.readonly
            } as ITextareaInput;
            return <TextareaInput {...stringProps} />;
        }
        if (props.answerType === VeilederAnswerEnum.dropdown) {
            let options = props.alternativeValues?.map((alt) => {
                return { text: alt, value: alt } as ISelectInputOption;
            });
            
            options.unshift({ text: "Velg en verdi", value: "" })

            const dropdownProps = {
                ...inputProps,
                type: InputElementEnum.select,
                options,
                value: props.stringValue,
                disabled: props.disabled || props.readonly,
            } as ISelectInput;
            return <SelectInput {...dropdownProps} />;
        }
        if (props.answerType === VeilederAnswerEnum.radiobutton || props.answerType === VeilederAnswerEnum.alternatives) {
            const inputs = props.alternativeValues?.map((alt, i) => {
                return {
                    ...inputProps,
                    type: InputElementEnum.radio,
                    label: alt,
                    value: alt,
                    index: i,
                    checked: props.stringValue ? (alt === props.stringValue) : (props.selectedValues?.includes(alt, 0)),
                    disabled: props.disabled || props.readonly,
                } as IRadioInput;
            });
            const radioGroupProps = {
                ...inputProps,
                type: InputElementEnum.radioGroup,
                inputs,
                value: props.stringValue,
                legend: props.questionText,
                disabled: props.readonly || props.readonly,
                debugLabel: props.debugLabel
            } as IRadioGroup;
            return <RadioGroup {...radioGroupProps} />;
        }
        if (props.answerType === VeilederAnswerEnum.multiselect) {
            const inputs = props.alternativeValues?.map((alt, i) => {
                return {
                    ...inputProps,
                    type: InputElementEnum.checkbox,
                    value: alt,
                    index: i,
                    checked: props.selectedValues?.includes(alt, 0),
                    label: alt,
                    name: alt,
                    disabled: props.disabled || props.readonly,
                } as ICheckboxInput;
            });
            
            const checkboxGroupProps = {
                ...inputProps,
                type: InputElementEnum.checkboxGroup,
                inputs,
                value: props.selectedValues,
                legend: props.questionText,
                disabled: props.disabled || props.readonly,
                debugLabel: props.debugLabel
            } as ICheckboxGroup;
            return <CheckboxGroup {...checkboxGroupProps} />;
        }
    }

    return <div className={`veileder__question veileder__question--${VeilederAnswerEnum[props.answerType]}`}>{renderInput()}</div>;
};

export default VeilederQuestion;
